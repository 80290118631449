import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { DEFAULT_SKIN } from '../../../50-systems/StyleSystem/defaultSkin';
import { makeSkinModule } from '../../../50-systems/StyleSystem/makers/makeSkinModule';

internalModules.declareModule(() =>
    makeSkinModule({
        manifest: { name: 'DefaultEducationSkin' },
        skin: {
            ...DEFAULT_SKIN,
            welcomeWallpaper: {
                // TODO: Put this 👇 file into the assets folder of the module
                backgroundImage: `url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/wallpapers/default-education-wallpaper.png')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
            },
        },
    }),
);
