import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Color } from '../../../../../40-utils/color/Color';
import { useSkin } from '../../../../../40-utils/react-hooks/useSkin';
import { ISkin } from '../../../../../50-systems/StyleSystem/ISkin';

interface IModulesCatalogueComponentProps {
    children?: ReactNode;
}

/**
 * Catalogue (list) of modules in module store
 * Note: This component should contain <ModuleBoxComponent as chilren
 */
export function ModulesCatalogueComponent({ children }: IModulesCatalogueComponentProps) {
    const { skin } = useSkin();

    return (
        <ModulesCatalogueStyle {...{ skin }}>
            {children}

            {[null, null, null /* <- Note: To propperly align the last module in the view */].map((_, index) => (
                <div key={index} className="module" style={{ border: 'none' }}></div>
            ))}
        </ModulesCatalogueStyle>
    );
}

const ModulesCatalogueStyle = styled.div<{ skin: ISkin }>`
    /*/
    outline: 1px dotted red; /**/

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .module {
        /*/
        outline: 1px dotted green; /**/

        position: relative;
        overflow: visible /* <- Note: to be able to see Add/Remove button */;
        min-width: 180px;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;

        margin: 10px;
        padding: 15px 15px;

        box-sizing: border-box;
        border: solid 2px ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
        border-radius: ${({ skin }) => skin.borderRadius}px;
    }
    .module .onHover {
        display: none;
    }
    .module:hover .onHover {
        display: block;
    }
    .module .image {
        /*background-image: url('https://www.h-edu.cz/media/favicon.png');*/
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/package.svg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top center;
        width: 50px;
        min-height: 50px;
        float: left;
        margin-right: 15px;
        margin-bottom: 15px;

        font-size: 35px;
    }
    .module h2 {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 0;
        margin-top: 8px;
    }
    .module h3 {
        font-size: 0.75rem;
        opacity: 0.6;
        margin-bottom: 0;
    }
    .module .desc-content {
        clear: both;
        width: 100%;
        overflow-wrap: break-word;
    }

    .module .warning {
        /*border: 2px solid darkgoldenrod;*/
        color: ${({ skin }) => Color.from(skin.colors.warning).opaque.toString()};
        margin-top: 10px;
        /*padding: 4px;*/
        border-radius: ${({ skin }) => skin.borderRadius}px;
    }

    .module .modules-badge .text {
        padding: 0 5px;
    }
    .module .badge-wrapper {
        position: absolute;
        top: -10px;
        right: -10px;
        display: flex;
        flex-direction: row;
    }
    .module .modules-badge {
        font-size: 0.7rem;
        padding: 3px 4px;
        color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        border-radius: ${({ skin }) => (skin.circles ? '25px' : '0px')};
        background-repeat: no-repeat;
        padding-right: 21px;
        line-height: 20px;
        height: 25px;
        margin-left: 10px;
        position: relative;
    }
    .module .modules-badge.badge-added {
        background-color: ${({ skin }) => Color.from(skin.colors.success).opaque.toString()};
        color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/yes-w@2x.png');
        background-size: 14px;
        background-position: right 5px center;
    }
    .module .modules-badge.badge-remove {
        background-color: ${({ skin }) => Color.from(skin.colors.danger).opaque.toString()};
        color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/bin-w@2x.png');
        background-position: right 7px center;
        background-size: 12px;
        cursor: pointer;
    }
    .module .modules-badge.badge-progress {
        background-color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
        color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/options@2x.png');
        background-size: 14px;
        background-position: right 6px center;
    }
    .module .modules-badge.badge-add {
        background-color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
        color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/add-w@2x.png');
        background-size: 14px;
        background-position: right 5px center;
        cursor: pointer;
    }

    .module .modules-badge.badge-buy {
        background-color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
        color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/add-w@2x.png');
        ${/* ☝️ TODO: @roseckyj Maybe some special icon for buy 💸💰 */ ``}
        background-size: 14px;
        background-position: right 5px center;
        cursor: pointer;
    }

    .module .modules-badge.badge-unavailable {
        background-color: ${({ skin }) => Color.from(skin.colors.primary).opaque.toString()};
        color: ${({ skin }) => Color.from(skin.colors.white).opaque.toString()};
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/hand-w@2x.png');
        ${/* ☝️ TODO: @roseckyj Maybe some special icon for unavailable 🚫 */ ``}
        background-size: 14px;
        background-position: right 5px center;
        cursor: pointer;
    }
    .module .modules-badge.badge-options {
        background-color: ${({ skin }) => Color.from(skin.colors.light).opaque.toString()};
        color: ${({ skin }) => Color.from(skin.colors.dark).opaque.toString()};
        background-image: url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/options@2x.png');
        background-size: 14px;
        background-position: right 6px center;
        cursor: pointer;
    }
    .module .invisible-dropdown {
        opacity: 0;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        cursor: pointer;
    }
`;

/**
 * TODO: [🏴‍☠️] Across the projects components+files with styled name call WelcomeModalStyle (not WelcomeModalDiv)
 * TODO: [🩱] Probbably do not suffix components with "Component" (or make better decisions [🏊‍♂️])
 */
