import { Transform } from 'xyzt';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeIconModule } from '../50-systems/ModuleStore/makers/makeIconModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';

internalModules.declareModule(() =>
    makeIconModule({
        manifest: {
            name: '@collboard/internal/home-tool',
            deprecatedNames: ['@collboard/home-tool', 'HomeTool'],
            title: { en: 'Home button', cs: 'Zpět na výchozí bod' },

            categories: ['Basic', 'Productivity'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/home-center.svg',

            author: Authors.collboard,
            requirePermissions: ['view'],
        },
        toolbar: ToolbarName.Navigation,
        async icon(systems) {
            const { appState } = await systems.request('appState');
            return {
                name: 'home-center',
                order: 100,

                icon: 'home-center',
                onClick: async () => {
                    appState.transform.next(Transform.neutral());
                },
            };
        },
    }),
);
