import { Registration } from 'destroyable';
import React from 'react';
import { Transform } from 'xyzt';
import { Separator } from '../30-components/menu/Separator';
import { string_color } from '../40-utils/typeAliases';
import { IOngoingMaterialOperation, IOngoingOperation } from '../50-systems/ArtVersionSystem/IOperation';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
import { PointArt } from '../71-arts/50-PointArt/50-PointArt';
import { PolygonArt } from '../71-arts/50-PolygonArt/PolygonArt';
import { touchFrameToArtFrame } from './FreehandTool';

internalModules.declareModule(() =>
    makeIconModuleOnModule({
        manifest: {
            name: '@collboard/internal/polygon-tool',
            deprecatedNames: '@collboard/polygon-tool',
            title: { en: 'Drawind of polygons', cs: 'Kreslení mnohostěnů' },
            // Note: for basic modules omitting the description: { en: '', cs: '' },

            categories: ['Basic', 'Art'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/triangle.svg' /* <- [0] Better */,

            author: Authors.collboard,
        },
        toolbar: ToolbarName.Tools,
        async icon(systems) {
            const { attributesSystem } = await systems.request('attributesSystem');
            return {
                //icon: ({ attributesSystem }) => ({
                name: 'polygon' /* For triggering externally */,
                autoSelect: true,

                order: 10,

                icon: 'triangle' /* <- [0] Better */,
                boardCursor: 'crosshair',
                menu: (
                    <>
                        {attributesSystem.inputRender('weight')}
                        <Separator />
                        {attributesSystem.inputRender('color')}
                    </>
                ),
            };
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const {
                    touchController,
                    collSpace,
                    appState,
                    attributesSystem,
                    materialArtVersioningSystem,
                    virtualArtVersioningSystem,
                } = await systems.request(
                    'touchController',
                    'collSpace',
                    'appState',
                    'attributesSystem',
                    'materialArtVersioningSystem',
                    'virtualArtVersioningSystem',
                );

                let drawingInProgress: null | {
                    operation: IOngoingMaterialOperation;
                    firstPointArt: PointArt;
                    firstPointArtOperation: IOngoingOperation;
                } = null;

                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe((touch) => {
                        appState.cancelSelection();

                        // TODO: [👘] Nicer syntax
                        const frame = touchFrameToArtFrame(touch.firstFrame);
                        const point = collSpace.pickPoint(frame.position).point;

                        if (!drawingInProgress) {
                            // Note: First point of the polygon

                            const artInProcess = new PolygonArt(
                                {
                                    points: [point],
                                    spotSize: 0 /* <- [🎢] */,
                                    edgeSize: attributesSystem.getAttributeValue('weight').value as number,
                                    isFilled: true /* <- TODO: Get this from attributes */,
                                },
                                {
                                    color: attributesSystem.getAttributeValue('color').value as string_color,
                                    // TODO: Allow to choose different fill, edge and spot
                                },
                                Transform.neutral(/* TODO: [🈁]  */),
                            );

                            const firstPointArt = new PointArt(
                                { spotSize: 25 /* <- TODO: What is the best size + Add to ISkin / some UI config */ },
                                {
                                    /**
                                     * TODO: Some material created from skin
                                     * TODO: More interesting point/dot shape ❌, ⭕, 🚩,...
                                     */
                                    color: '#009edd',
                                },
                                Transform.translate(point),
                            );

                            const operation = materialArtVersioningSystem.createPrimaryOperation();
                            drawingInProgress = {
                                operation: operation.newArts(artInProcess),
                                firstPointArt,
                                firstPointArtOperation: virtualArtVersioningSystem
                                    .createPrimaryOperation()
                                    .newArts(firstPointArt),
                            };
                        } else if (!drawingInProgress.firstPointArt.isNear(point)) {
                            // Note: Middle point of the polygon

                            drawingInProgress.operation.updateWithMutatingCallback((polygonArt) => {
                                polygonArt.shape.points.push(point);
                            });
                        } else {
                            // Note: Ending of the polygon

                            drawingInProgress.operation.persist();
                            drawingInProgress.firstPointArtOperation.destroy();
                            drawingInProgress = null;
                        }
                    }),
                );
            },
        },
    }),
);

/**
 * TODO: [🍩][🧠] Requesting systems non-magically
 * TODO: [🍩] Omitting systems.request should fail bacause of unrequested systems.
 * TODO: What about unfinished polygons WHEN the tool is switched
 * TODO: Separate tool for freehand polygons (In commit bdc6d690536b6def2f7bc61701b53135e2832317 was working)
 * TODO: Should persist everytine a new point is placed OR just at the end of the opreation?
 * TODO: UX What about press and drag
 * TODO: UX What about multitouch - drawingInProgress is singleton
 * TODO: UX Make also ending point interactive
 * TODO: Fallback to PointArt and LineArt
 * TODO: [🈁] Align points and extract transform from them - points should be aligned to [0,0] (and maybe between [0,0] and [1,1])
 */
