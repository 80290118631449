import React from 'react';
import { amIEmbededInIFrame } from '../../40-utils/amIEmbededInIFrame';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeUserInterfaceModule } from '../../50-systems/ModuleStore/makers/makeUserInterfaceModule';
import { UserInterfaceElementPlace } from '../../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';

internalModules.declareModule(() =>
    makeUserInterfaceModule({
        manifest: {
            name: 'CollboardXmasLogo',
            title: { en: 'Christmas logo', cs: 'Vánoční logo' },
            flags: {
                isDeprecated: true,
                isHidden: true,
            },
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/logo-xmas.png',
            author: Authors.collboard,
            categories: ['Xmas'],
        },

        place: UserInterfaceElementPlace.EdgeTop,

        async render(system) {
            const { boardSystem } = await system.request('boardSystem');

            // TODO: Pack logo image with this module
            // TODO: Indipendent style with style system
            return (
                <>
                    {amIEmbededInIFrame() ? (
                        <span className="brand">
                            <img
                                src={`https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/logo-xmas.png`}
                                onClick={(event) => {
                                    event.preventDefault();
                                    boardSystem.navigateBoard(null);
                                }}
                                alt="Collboard.com"
                            />
                        </span>
                    ) : (
                        <a href="/" className="brand">
                            <img
                                src={`https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/logo-xmas.png`}
                                onClick={(event) => {
                                    event.preventDefault();
                                    boardSystem.navigateBoard(null);
                                }}
                                alt="Collboard.com"
                            />
                        </a>
                    )}
                </>
            );
        },
    }),
);
