import React from 'react';
import styled from 'styled-components';
import { DisplayOn } from '../../../30-components/utils/DisplayOn';
import { Authors } from '../../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { makeUserInterfaceModule } from '../../../50-systems/ModuleStore/makers/makeUserInterfaceModule';
import { UserInterfaceElementPlace } from '../../../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';
import { CornerstoneArt } from '../../../71-arts/30-CornerstoneArt';

internalModules.declareModule(() =>
    makeUserInterfaceModule({
        manifest: {
            /* TODO: Remove specific things connected with this module (styles and maybe more) from core to this module OR make from this the system*/

            name: '@collboard/internal/board-name',
            deprecatedNames: ['@collboard/board-name', 'BoardName'],
            title: { en: 'Name of the board', cs: 'Jméno tabule' },
            description: {
                // TODO: En: '',
                // TODO: Cs: '',
            },
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/logo-small.png',
            author: Authors.collboard,
            requirePermissions: ['view'],
        },

        place: UserInterfaceElementPlace.Social,
        order: 10,
        async render(systems) {
            const { materialArtVersioningSystem, translationsSystem } = await systems.request(
                'materialArtVersioningSystem',
                'translationsSystem',
            );

            const disabled = !materialArtVersioningSystem.permissions.edit;

            return (
                <DisplayOn desktop tablet>
                    <BoardNameStyled
                        key={
                            1 /* TODO: I do not know why should there be keys but if they are not here it shows warning "Each child in a list should have..." */
                        }
                        type="text"
                        className="stealth"
                        ref={(element) => {
                            if (!element) return;
                            // TODO: There should be some unsubscription
                            // TODO: Probably update the name through observable pattern not this weird way
                            materialArtVersioningSystem.commitsObservable.subscribe(async (commit) => {
                                if (commit.art instanceof CornerstoneArt) {
                                    element.value = commit.art.boardname;
                                }
                            });
                        }}
                        defaultValue={materialArtVersioningSystem.cornerstoneArts.value.boardname}
                        placeholder={
                            disabled
                                ? translationsSystem.translate(`BoardName / untitled`, `Nepojmenovaná tabule`)
                                : translationsSystem.translate(`BoardName / placeholder`, `Napište jméno tabule`)
                        }
                        onChange={(event) => {
                            if (disabled) {
                                return;
                            }
                            materialArtVersioningSystem
                                .createOperation('Change board name')
                                .takeArts(materialArtVersioningSystem.cornerstoneArts.value)
                                .updateWithMutatingCallback((cornerstoneArt) => {
                                    cornerstoneArt.boardname = event.target.value;
                                })
                                .persist();
                        }}
                        {...{ disabled }}
                    />
                </DisplayOn>
            );
        },
    }),
);

const BoardNameStyled = styled.input`
    height: 32px;
    margin: 5px 10px;
`;

/**
 * [🏯]
 */
