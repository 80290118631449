import React from 'react';
import { Authors } from '../../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { makeUserInterfaceModule } from '../../../50-systems/ModuleStore/makers/makeUserInterfaceModule';
import { UserInterfaceElementPlace } from '../../../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';
import { CloneBoardButtonComponent } from './CloneBoardButtonComponent';

/**
 * Note: In future this file will we in independent repository as external module.
 */

internalModules.declareModule(() =>
    makeUserInterfaceModule({
        manifest: {
            name: 'CloneBoardButton',
            title: { en: 'Button "Create your own copy"', cs: 'Tlačítko „Vytvořit vlastní kopii“' },
            description: {
                en: '', // TODO:
                cs: 'Můžete tabuli sdílet bezpečně pomocí odkazu pro zobrazení a ostatní si mohou vytvořit její kopii a tu editovat.', // TODO: Better text
            },

            categories: ['Productivity', 'Buttons', 'Template'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.90/icons/copy-material.svg',

            author: Authors.collboard,
            requirePermissions: ['view'],
        },
        place: UserInterfaceElementPlace.EdgeRight,
        order: 100,
        async render(systems) {
            await systems.request('routingSystem', 'translationsSystem', 'materialArtVersioningSystem');
            return <CloneBoardButtonComponent />;
        },
    }),
);

/**
 * TODO: [🍩][🧠] Requesting systems non-magically
 * TODO: [🍩] Omitting systems.request should fail bacause of unrequested systems.
 */
